import styled from '@emotion/styled'
import { theme } from '@mindfulchefuk/design-system'

export const OnboardingLayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  padding-top: ${theme.space[24]};
  position: relative;

  ${theme.media.md} {
    grid-template-columns: 1fr auto;
    padding-top: ${theme.space[56]};
  }
`

export const OnboardingLayoutMain = styled.div`
  margin-bottom: ${theme.space[24]};

  &:only-of-type {
    grid-column: 1 / -1;
  }
`

export const OnboardingLayoutAside = styled.div`
  margin-bottom: ${theme.space[40]};
  ${theme.media.md} {
    margin-left: ${theme.space[80]};
    width: ${theme.space[320]};
  }
`
