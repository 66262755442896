import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@mindfulchefuk/types/store'

interface Totals {
  total: number
  originalTotal: number
}

// When the onboarding basket is updated, the basket totals are momentarily
// reset while the voucher preview api is called. This hook preserves the
// previous values until isBasketLoading is false again
export const useRecipeCartTotals = (): Totals => {
  const [basketTotal, setBasketTotal] = useState(0)
  const [basketOriginalTotal, setBasketOriginalTotal] = useState(0)

  const { total, totalWithDiscount, hasVoucher, isBasketLoading } = useSelector(
    (state: RootState) => ({
      total: state.basket.total,
      totalWithDiscount: state.basket.totalWithDiscount,
      hasVoucher: !!state.basket.savedVoucherCode,
      isBasketLoading: state.basket.isBasketLoading,
    })
  )

  useEffect(() => {
    if (!hasVoucher) {
      setBasketTotal(total)
      setBasketOriginalTotal(null)
    } else if (hasVoucher && !isBasketLoading) {
      setBasketTotal(totalWithDiscount)
      setBasketOriginalTotal(total)
    }
  }, [total, totalWithDiscount, hasVoucher, isBasketLoading])

  return { total: basketTotal, originalTotal: basketOriginalTotal }
}
