import React, { FunctionComponent, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import { RootState } from '@mindfulchefuk/types/store'

import { Flex } from '@mindfulchefuk/design-system'
import { useGroupRecipesByQuantity } from '@mindfulchefuk/features/Onboarding/hooks/useGroupRecipesByQuantity'
import { clearLastAddedRecipe } from '@mindfulchefuk/actions/basketActions'

import { getMinimumMaximumRecipesForPortion } from '@mindfulchefuk/services/getMinimumMaximumRecipesForPortion'
import { OnboardingCartNavigationSummary } from '@mindfulchefuk/features/Cart/components/CartNavigation/Onboarding/OnboardingCartNavigationSummary'
import { CartButton } from '@mindfulchefuk/features/Cart/components/CartNavigation/CartButton'
import { useNewestItem } from '@mindfulchefuk/features/Cart/useNewestItem'
import { CartFlag } from '@mindfulchefuk/features/Cart/components/CartNavigation/CartFlag'

type Props = {
  toggleDrawerOpen: () => void
}

export const OnboardingCartNavigation: FunctionComponent<Props> = ({
  toggleDrawerOpen,
}) => {
  const { items, portionCount, totalRecipes, lastAddedRecipeId } = useSelector(
    (store: RootState) => ({
      items: store.basket.items,
      portionCount: store.basket.portionCount,
      totalRecipes: store.basket.items.length,
      lastAddedRecipeId: store.basket.lastAddedRecipeId,
    }),
    shallowEqual
  )

  const dispatch = useDispatch()
  const recipes = useGroupRecipesByQuantity(items)
  const lastAddedRecipe = recipes.find(({ id }) => lastAddedRecipeId === id)
  const newestItem = useNewestItem(null, lastAddedRecipe)

  useEffect(() => {
    return () => {
      dispatch(clearLastAddedRecipe())
    }
  }, [dispatch])

  const { maximumRecipes } = getMinimumMaximumRecipesForPortion({
    portionCount,
    journey: 'onboarding',
  })

  return (
    <>
      <Flex>
        <OnboardingCartNavigationSummary
          key="cart-navigation-summary"
          maxRecipes={maximumRecipes}
          totalRecipes={totalRecipes}
        />
        <CartButton newestItem={newestItem} onClick={toggleDrawerOpen}>
          {totalRecipes}
        </CartButton>
      </Flex>
      <CartFlag item={newestItem} />
    </>
  )
}
