import React, { ReactElement } from 'react'
import plural from '@mindfulchefuk/utils/plural'

import { Box, Flex, Text } from '@mindfulchefuk/design-system'

export type TOnboardingCartNavigationSummaryProps = {
  maxRecipes: number
  totalRecipes: number
}

export const OnboardingCartNavigationSummary = ({
  maxRecipes,
  totalRecipes,
}: TOnboardingCartNavigationSummaryProps): ReactElement => {
  const recipeText = plural('recipe')(totalRecipes)

  const headingText =
    totalRecipes < maxRecipes
      ? `${totalRecipes} ${recipeText} - add up to ${maxRecipes}`
      : `${totalRecipes} ${recipeText} - recipe box full`

  return (
    <Flex
      align="center"
      mr={28}
      data-testid="cart-navigation-summary"
      display={['none', 'flex']}
      width={208}
    >
      <Box>
        <Text as="h1" variant="body-sm-500-caps" color="aubergine" mb={10}>
          {headingText}
        </Text>
      </Box>
    </Flex>
  )
}
