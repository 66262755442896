import React, { VoidFunctionComponent } from 'react'
import toCurrency from '@mindfulchefuk/utils/toCurrency'
import plural from '@mindfulchefuk/utils/plural'
import { BasketBannerProps } from '@mindfulchefuk/features/Onboarding/components/ChooseRecipes/BasketBanner/BasketBanner.types'
import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  ResetButton,
  Text,
} from '@mindfulchefuk/design-system'
import { Spinner } from '@mindfulchefuk/components/Loader'
import { getMinimumMaximumRecipesForPortion } from '@mindfulchefuk/services/getMinimumMaximumRecipesForPortion'
import { CookieBanner } from '@mindfulchefuk/features/Cookies/components'

export const BasketBanner: VoidFunctionComponent<BasketBannerProps> = ({
  numRecipes,
  price,
  portionCount,
  discountApplied,
  totalWithDiscount,
  isBasketLoading,
  onPressContinue,
  toggleDrawerOpen,
}) => {
  const pricePerPerson =
    (discountApplied ? totalWithDiscount : price) / portionCount
  const recipesLabel = `${numRecipes} ${plural('recipe')(numRecipes)}`
  const { minimumRecipes, maximumRecipes } = getMinimumMaximumRecipesForPortion(
    {
      portionCount,
      journey: 'onboarding',
    }
  )

  const disabled = numRecipes < minimumRecipes

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="white"
      borderTopWidth={1}
      borderColor="pepper-100"
      z={9}
      data-testid="recipes-basket-banner"
    >
      <Container px={[12, 24, 48]} position="relative" py={16}>
        <Box
          position="absolute"
          right={[12, 24, 48]}
          backgroundColor="white"
          borderColor="pepper-100"
          borderStyle="solid"
          borderWidth={1}
          borderBottomWidth={0}
          borderRadius="10px 10px 0 0"
          width={44}
          height={40}
          top={-40}
          display="flex"
        >
          <ResetButton
            display="block"
            fullWidth
            type="button"
            aria-label="Open Cart"
            onClick={() => toggleDrawerOpen()}
          >
            <Icon type="chevron-up" strokeWidth="3px" size="26px" />
          </ResetButton>
        </Box>
        <Flex alignItems="center" justify={['space-between', 'flex-end']}>
          <Box pr={{ sm: 40 }} position="relative">
            {isBasketLoading && (
              <Flex
                position="absolute"
                bg="white.50"
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size={24} />
              </Flex>
            )}
            {numRecipes === 0 ? (
              <Text
                as="span"
                variant={['body-sm-500-caps', 'body-xl-500-caps']}
              >
                Add {minimumRecipes} to {maximumRecipes} recipes
              </Text>
            ) : (
              <>
                <Text
                  as="span"
                  variant="body-sm-500-caps"
                  display={['none', 'inline']}
                >
                  You added{' '}
                </Text>

                <Text as="span" variant="body-sm-500-caps">
                  {recipesLabel} for {portionCount}{' '}
                  {portionCount === 1 ? 'person' : 'people'}
                </Text>

                <Box>
                  {discountApplied && (
                    <Text
                      as="span"
                      variant={['heading-xxs', 'heading-sm']}
                      color="pepper-500"
                      textDecoration="line-through"
                      mr={2}
                    >
                      {toCurrency(price)}
                    </Text>
                  )}

                  <Text
                    as="span"
                    variant={['heading-xxs', 'heading-sm']}
                    mr={2}
                  >
                    {discountApplied
                      ? toCurrency(totalWithDiscount)
                      : toCurrency(price)}
                  </Text>

                  {portionCount > 1 && (
                    <Text as="span" variant="body-sm" color="pepper">
                      ({toCurrency(pricePerPerson)} pp)
                    </Text>
                  )}

                  <Text as="p" variant="body-sm" color="pepper">
                    {numRecipes < minimumRecipes
                      ? `Add ${minimumRecipes} to ${maximumRecipes} recipes`
                      : `Add up to ${maximumRecipes} recipes`}
                  </Text>
                </Box>
              </>
            )}
          </Box>
          <Box
            borderColor={{ sm: 'aubergine' }}
            borderLeftWidth={{ sm: 1 }}
            pl={{ sm: 40 }}
          >
            <Button
              variant="primary"
              size={['medium', 'large']}
              type="button"
              disabled={disabled || isBasketLoading}
              onClick={onPressContinue}
              data-testid="continue-cta"
            >
              Continue
            </Button>
          </Box>
        </Flex>
      </Container>
      <CookieBanner isNested />
    </Box>
  )
}
