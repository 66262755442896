import {
  Recipe,
  RecipeWithQuantity,
} from '@mindfulchefuk/features/Recipes/interfaces'

export function useGroupRecipesByQuantity(
  recipes: Recipe[]
): RecipeWithQuantity[] {
  const mergedRecipes = [...recipes].reduce((grouped, recipe) => {
    const matchingRecipe = grouped.find(({ id }) => recipe.id === id)

    if (!matchingRecipe) {
      grouped.push({ ...recipe, quantity: 1 })
    } else {
      matchingRecipe.quantity += 1
    }
    return grouped
  }, [])

  return mergedRecipes
}
