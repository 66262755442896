import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import { useRouter } from 'next/router'

import {
  logAddRecipeToCart,
  logRemoveAllRecipeFromCart,
  logRemoveRecipeFromCart,
} from '@mindfulchefuk/utils/analytics'
import {
  logCheckoutStart,
  logSocialCheckoutStart,
} from '@mindfulchefuk/features/Onboarding/utils/onboardingAnalytics'
import { instrumentProductAdded } from '@mindfulchefuk/utils/analytics/amplitude/instrument'
import { updateBasket } from '@mindfulchefuk/actions/basketActions'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { RecipeWithQuantity } from '@mindfulchefuk/features/Recipes/interfaces'
import { ProductType } from '@mindfulchefuk/analytics'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'

import {
  logOnboardingClearRecipesFromBasket,
  logOnboardingConfirmRecipesFromBasket,
  logOnboardingDecrementRecipeFromBasket,
  logOnboardingIncrementRecipeFromBasket,
} from '@mindfulchefuk/utils/analytics/ga/onboardingRecipeBasket'

type ClickOrigination = 'modal' | 'recipe' | 'basket'

export function useChooseRecipesActions() {
  const dispatch = useDispatch()
  const router = useRouter()
  const { isNewCustomer } = useIsNewCustomer()

  const { items, total, totalWithDiscount } = useSelector(
    (store: RootState) => ({
      items: store.basket.items,
      total: store.basket.total,
      totalWithDiscount: store.basket.totalWithDiscount,
    }),
    shallowEqual
  )

  const handleAddRecipe =
    (clickOrigination: ClickOrigination) => (recipe: RecipeWithQuantity) => {
      dispatch(updateBasket(recipe, 'add'))
      logAddRecipeToCart(recipe)
      if (clickOrigination === 'basket')
        logOnboardingIncrementRecipeFromBasket()

      instrumentProductAdded({
        productId: recipe.id,
        productName: recipe.title,
        productType: 'recipe',
        productCategory: ProductType.RECIPES,
        productSubCategory: recipe.cuisine,
        productPrice: recipe.price,
        portionCount: recipe.portions,
        productQuantity: 1,
        pageSource: clickOrigination === 'modal' ? 'recipes details' : null,
      })
    }

  const handleClickFromModal = handleAddRecipe('modal')
  const handleClickFromRecipeCard = handleAddRecipe('recipe')
  const handleIncrementRecipeFromBasket = handleAddRecipe('basket')

  const handleConfirmClick = () => {
    logCheckoutStart({ items, price: total, totalWithDiscount })
    logSocialCheckoutStart()

    if (isNewCustomer) {
      return router.push(routesConfig.postLoginOnboardingShipping.pathname)
    }
    router.push(routesConfig.aboutYou.pathname)
  }

  const handleConfirmClickFromTheBasket = () => {
    logOnboardingConfirmRecipesFromBasket()
    handleConfirmClick()
  }

  const handleRemoveAllRecipe = (recipe: RecipeWithQuantity) => {
    dispatch(updateBasket(recipe, 'removeAll'))
    logOnboardingClearRecipesFromBasket()
    logRemoveAllRecipeFromCart(recipe as RecipeWithQuantity)
  }

  const handleRemoveRecipe = (recipe: RecipeWithQuantity) => {
    dispatch(updateBasket(recipe, 'remove'))
    logRemoveRecipeFromCart(recipe)
  }

  const handleDecrementRecipeFromBasket = (recipe: RecipeWithQuantity) => {
    logOnboardingDecrementRecipeFromBasket()
    handleRemoveRecipe(recipe)
  }

  return {
    handleClickFromModal,
    handleClickFromRecipeCard,
    handleConfirmClick,
    handleConfirmClickFromTheBasket,
    handleRemoveAllRecipe,
    handleRemoveRecipe,
    handleIncrementRecipeFromBasket,
    handleDecrementRecipeFromBasket,
  }
}
